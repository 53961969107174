
export default {
    // components: {
    //   Swiper
    // },
    props: [],
    name: 'product',
    data() {
        return {
            activeIndex: '1',
            activeIndex2: '1'
        }
    },
    created() {
    },
    mounted() {

    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    }
}
